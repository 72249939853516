import React, { useMemo } from "react"
import { AnimatedSection } from "./AnimatedSection"

export const AnimatedSlidesList = ({
  sections,
  onClickButton,
  activeIndex,
}) => {
  const activeSection = useMemo(() => sections[activeIndex], [
    activeIndex,
    sections,
  ])
  return (
    <AnimatedSection
      onClickButton={onClickButton}
      key={activeIndex}
      section={activeSection}
    />
  )
}
