import React, { useEffect, useRef, useState } from "react"
import * as Styles from "./TestimoniesCards.styles"
import { ButtonSteps } from "@components/Testimonies/ButtonSteps"
import Slider from "react-slick"
import { PagingIndicator } from "@components/Testimonies/Testimonies.styles"
import { Box } from "rebass"
import { graphql, useStaticQuery } from "gatsby"

export function TestimoniesCards() {
  const sliderRef = useRef()
  const { testimonies } = useStaticQuery(graphql`
    query {
      testimonies: allMarkdownRemark(
        filter: { fileAbsolutePath: { glob: "**/content/testimonials/*.md" } }
        sort: { fields: frontmatter___order }
      ) {
        edges {
          node {
            id
            frontmatter {
              company
              languages {
                language
                description
                clientName
                clientPosition
              }
              logo {
                childSvg {
                  content
                }
              }
            }
          }
        }
      }
    }
  `)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const testimoniesList = testimonies.edges
    .map(testimonial => ({
      id: testimonial.node.id,
      ...testimonial.node.frontmatter,
      translation: testimonial.node.frontmatter.languages.find(
        language => language.language === process.env.GATSBY_LANGUAGE
      ),
    }))
    .filter(testimonial => testimonial.translation)

  return (
    <Box
      sx={{
        ".slick-slide": {
          padding: "0 8px",
          boxSizing: "border-box",
        },
      }}
    >
      <Slider
        ref={sliderRef}
        {...{
          key: isClient ? "client" : "server",
          responsive: isClient
            ? [
                {
                  breakpoint: 896,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]
            : null,
          initialSlide: 0,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          dots: true,
          appendDots: dots => (
            <>
              <ButtonSteps
                color="secondary"
                showLinkToCase={false}
                onClickNext={() => sliderRef.current?.slickNext()}
                onClickPrevious={() => sliderRef.current?.slickPrev()}
                mt={4}
              />
              <Styles.PagingIndicatorWrapper>
                {dots}
              </Styles.PagingIndicatorWrapper>
            </>
          ),
          customPaging: () => (
            <PagingIndicator color="linear-gradient(90deg, #E86D30 0%, #DB4653 100%)" />
          ),
        }}
      >
        {testimoniesList.map((testimonial, index) => (
          <TestmonyCard testimonial={testimonial} />
        ))}
      </Slider>
    </Box>
  )
}

function TestmonyCard({ testimonial }) {
  return (
    <Styles.Card key={testimonial.id}>
      <Styles.CardText
        dangerouslySetInnerHTML={{
          __html: `“${testimonial.translation.description}”`,
        }}
      />

      <Styles.DeponentWrapper>
        <Styles.CardLogo
          dangerouslySetInnerHTML={{
            __html: testimonial.logo.childSvg.content,
          }}
        />
        <Styles.Deponent>
          {testimonial.translation.clientName}
          <Styles.DeponentPosition>
            {testimonial.translation.clientPosition}
          </Styles.DeponentPosition>
        </Styles.Deponent>
      </Styles.DeponentWrapper>
    </Styles.Card>
  )
}
