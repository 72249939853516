import { Heading } from "rebass"
import React from "react"

export const Title = ({ color = "inherit", ...props }) => (
  <Heading
    sx={{
      fontSize: [24, 24, 40],
      lineHeight: 1.25,
      mb: 2,
      maxWidth: 500,
      color: "primary",
      textAlign: ["center", "center", "left"],
      fontWeight: "body",
      "p::after": {
        content: "'.'",
        color: "secondary",
      },
      strong: {
        fontWeight: "bold",
      },
    }}
    as="h2"
    {...props}
  />
)
