import React, { useRef, useCallback } from "react"
import * as Styles from "./LandingPage.styles"
import { navigate } from "gatsby"
import routes from "@utils/routes"
import Logo from "@components/Logo"
import "intersection-observer"
import { FormSection } from "./FormSection"
import { AnimatedSections } from "./AnimatedSections"
import { TestimoniesSection } from "./TestimoniesSection"
import { GartnerSection } from "./GartnerSection"
import { FooterSection } from "./FooterSection"
import { useOnExitIntent } from "@utils/hooks/useOnExitIntent"
import LandingPageModal from "@components/LandingPageModal"
import { SpecialBlock1 } from "./SpecialBlock1"
import { SpecialBlock2 } from "./SpecialBlock2"
import { useModal } from "@xapps/design-system"
import { manageCookies } from "@utils/manageCookies"

const logo = (
  <Logo
    sx={{
      width: "100%",
      svg: {
        width: ["109px", "109px", "122px"],
        margin: "0 auto",
      },
    }}
  />
)

export function LandingPage(props) {
  const { sections, showGartner, showTestimonies, children } = props
  const formRef = useRef()
  const nameInputRef = useRef()
  const focusOnForm = useCallback(() => {
    formRef.current.scrollIntoView({ behavior: "smooth" })
    setTimeout(() => nameInputRef.current.focus(), 1000)
  }, [formRef])

  const goToRequestBudget = () => navigate(routes.contato)

  const [modalElement, showIntentModal] = useModal(<LandingPageModal />)
  useOnExitIntent(() => {
    manageCookies.setExpiresInSeconds(60);
    showIntentModal();
  })

  return (
    <Styles.Wrapper>
      {modalElement}
      {children}

      <FormSection
        formSectionRef={formRef}
        nameInputRef={nameInputRef}
        landingPageData={props}
        logo={logo}
      />

      <AnimatedSections sections={sections} onClickButton={focusOnForm} />

      {props.specialBlock1?.title && <SpecialBlock1 {...props.specialBlock1} />}

      {props.specialBlock2?.title && <SpecialBlock2 {...props.specialBlock2} />}

      <TestimoniesSection show={showTestimonies} />

      <GartnerSection show={showGartner} onClickButton={goToRequestBudget} />

      <FooterSection showModal={showIntentModal} logo={logo} />
    </Styles.Wrapper>
  )
}
