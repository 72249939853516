import React from "react"
import { Title } from "./Title"
import * as Styles from "./SpecialBlock2.styles"
import Image from "gatsby-image"

export const SpecialBlock2 = ({ title, text, image }) => {
  return (
    <Styles.Wrapper>
      <Styles.Container>
        <Styles.TitleMobileWrapper>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
        </Styles.TitleMobileWrapper>
        <Styles.ImageWrapper>
          <Image
            style={{
              position: "relative",
              right: "-25%",
            }}
            fluid={image.childImageSharp.fluid}
          />
        </Styles.ImageWrapper>
        <Styles.TextWrapper>
          <Styles.TitleDesktopWrapper>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </Styles.TitleDesktopWrapper>
          <Styles.Text dangerouslySetInnerHTML={{ __html: text }} />
        </Styles.TextWrapper>
      </Styles.Container>
    </Styles.Wrapper>
  )
}
