import React from "react"
import { Box } from "rebass"
import { range } from "lodash"
import classNames from "classnames"

export function ActiveSlide({ numberOfSlides, activeIndex, setActiveIndex }) {
  return (
    <Box
      sx={{
        mb: 2,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",

        ".indicator": {
          transition: "background 0.4s ease-in",
          background: "#BDBDBD",
          borderRadius: 99,
          cursor: "pointer",
          "&:not(:last-child)": {
            mr: 2,
          },
          "&.active": {
            background: "linear-gradient(90deg, #E86D30 0%, #DB4653 100%);",
          },
        },
      }}
    >
      {range(0, numberOfSlides).map(index => (
        <Box
          className={classNames({
            active: activeIndex === index,
            indicator: true,
          })}
          onClick={() => setActiveIndex(index)}
          key={index}
          sx={{ width: 12, height: 12 }}
        ></Box>
      ))}
    </Box>
  )
}
