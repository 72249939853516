import React, { useState, useEffect } from "react"
import { Box } from "rebass"
import { AnimatedSlidesList } from "./AnimatedSlidesList"
import { ActiveSlide } from "./ActiveSlide"

export const AnimatedSections = ({ sections, onClickButton }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      if (activeIndex === sections.length - 1) {
        setActiveIndex(0)
      } else {
        setActiveIndex(activeIndex + 1)
      }
    }, 7500)
    return () => clearInterval(timer)
  }, [activeIndex, sections.length])
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <AnimatedSlidesList
        onClickButton={onClickButton}
        sections={sections}
        activeIndex={activeIndex}
      />
      <ActiveSlide
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        numberOfSlides={sections.length}
      />
    </Box>
  )
}
