import React from "react"
import Translate, { TranslateHTML } from "@components/Translate"
import { useTranslate } from "@utils/hooks/useTranslate"
import { Box } from "rebass"
import { useAskForBudget } from "@utils/hooks/useAskForBudget"
import Fade from "react-reveal/Fade"
import * as Style from "./LandingPageModal.styles"
import { Botao, InputTelefone, Input } from "@xapps/design-system"
import { IdsDosBotoesDeConversaoDoLeadsCenter } from "@constants/forms"

const CONTACT_MAUTIC_FORM_ID = 18

function LandingPageModal() {
  const { handleSubmit, handleChange, values, isSubmitting } = useAskForBudget(
    CONTACT_MAUTIC_FORM_ID,
    "formularioebook"
  )

  const formatMessage = useTranslate()

  return (
    <Style.Wrapper>
      <Fade>
        <Style.ImageWrapper />
        <Style.DataFormWrapper>
          <Box>
            <Style.Label>
              <Translate id="landingPagePopup.title" />
            </Style.Label>
            <Style.Description>
              <TranslateHTML id={"landingPagePopup.description"} />
            </Style.Description>
            <Style.Description>
              <TranslateHTML id={"landingPagePopup.subDescription"} />
            </Style.Description>
          </Box>

          <Style.FormWrapper as="form" onSubmit={handleSubmit}>
            <Input
              containerProps={{ marginBottom: 24 }}
              id="firstName"
              name="firstName"
              required
              value={values.firstName}
              rotulo={formatMessage("exitIntentPopup.formFirstName")}
              onChange={handleChange}
            />

            <Input
              containerProps={{ marginBottom: 24 }}
              name="lastName"
              required
              value={values.lastName}
              rotulo={formatMessage("exitIntentPopup.formLastName")}
              onChange={handleChange}
            />

            <Input
              containerProps={{ marginBottom: 24 }}
              name="email"
              type="email"
              required
              value={values.email}
              rotulo={formatMessage("exitIntentPopup.formEmail")}
              onChange={handleChange}
            />

            <InputTelefone
              nome="phone"
              required
              international
              containerProps={{ marginBottom: 24 }}
              value={values.phone}
              onChange={handleChange}
              rotulo={formatMessage("exitIntentPopup.formTelefone")}
            />

            <input
              value={
                (values.formId = IdsDosBotoesDeConversaoDoLeadsCenter.modal)
              }
              type="hidden"
              id="formId"
              name="formId"
              onChange={handleChange}
            />

            <Botao type="submit" desabilitado={isSubmitting}>
              <Translate id="landingPagePopup.submitButton" />
            </Botao>
          </Style.FormWrapper>
        </Style.DataFormWrapper>
      </Fade>
    </Style.Wrapper>
  )
}

export default LandingPageModal
