import React from "react"
import * as Styles from "./LandingPage.styles"
import { TestimoniesCards } from "./TestimoniesCards"
import { PageGrid } from "./PageGrid"
import Translate, { TranslateHTML } from "@components/Translate"

export const TestimoniesSection = ({ show }) => {
  return (
    <>
      {show && (
        <Styles.Section backgroundColor="alternateSectionBackground">
          <PageGrid>
            {{
              fullWidth: (
                <>
                  <Styles.FirstLine>
                    <Translate id="testimoniesSlider.firstLine" />
                  </Styles.FirstLine>
                  <Styles.Title color="primary">
                    <p>
                      <TranslateHTML id="testimoniesSlider.title" />
                    </p>
                  </Styles.Title>
                  <TestimoniesCards />
                </>
              ),
            }}
          </PageGrid>
        </Styles.Section>
      )}
    </>
  )
}
