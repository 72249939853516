import { Box, Flex, Text as TextRebass } from "rebass"
import React from "react"

export const Wrapper = props => (
  <Flex
    sx={{
      width: "100vw",
      bg: "white",
      paddingX: 3,
      paddingY: 4,
      margin: "0 auto",
      overflow: "hidden",
      position: "relative",
    }}
    {...props}
  />
)

export const Container = props => (
  <Flex
    sx={{
      alignItems: "center",
      maxWidth: 1090,
      minHeight: 500,
      margin: "0 auto",
      justifyContent: "flex-end",
      flexDirection: ["column", "column", "row-reverse"],
    }}
    {...props}
  />
)

export const TitleMobileWrapper = props => (
  <Box
    sx={{
      display: ["block", "block", "none"],
    }}
    {...props}
  />
)

export const TitleDesktopWrapper = props => (
  <Box
    sx={{
      display: ["none", "none", "block"],
      mb: 40,
    }}
    {...props}
  />
)

export const TextWrapper = props => (
  <Box
    sx={{
      maxWidth: [null, null, "60%"],
    }}
    {...props}
  />
)

export const ImageWrapper = props => (
  <Box
    sx={{
      width: ["100%", "100%", "50%"],
      position: [null, null, "absolute"],
      right: 0,
    }}
    {...props}
  />
)
export const Text = props => (
  <TextRebass
    sx={{
      fontSize: [1, 2, 2],
      color: "primary",
    }}
    {...props}
  />
)
