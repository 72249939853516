import React from "react"
import * as Styles from "./SpecialBlock1.styles"
import * as Title from "./Title"
import Image from "gatsby-image"
import { Topic } from "./Topic"

export const SpecialBlock1 = ({ title, image, topics }) => {
  return (
    <Styles.Wrapper>
      <Styles.Container>
        <Title.Title dangerouslySetInnerHTML={{ __html: title }} />
        <Styles.ContentWrapper>
          <Styles.ImageWrapper>
            <Image
              style={{
                width: "100%",
                height: "auto",
                maxHeight: 500,
              }}
              fluid={image.childImageSharp.fluid}
            />
          </Styles.ImageWrapper>
          <Styles.Topics>
            {topics.map((topic, index) => (
              <Topic key={index} {...topic} />
            ))}
          </Styles.Topics>
        </Styles.ContentWrapper>
      </Styles.Container>
    </Styles.Wrapper>
  )
}
