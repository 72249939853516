import React, { useState } from "react"
import * as Styles from "./LandingPage.styles"
import { useAskForBudget } from "@utils/hooks/useAskForBudget"
import routes from "@utils/routes"
import { Link } from "gatsby"
import { PageGrid } from "./PageGrid"
import { useTranslate } from "@utils/hooks/useTranslate"
import { useCampaignCookies } from "@utils/hooks/useStoreCampaignVariablesInCookies"
import { Botao, InputTelefone, Input } from "@xapps/design-system"

import { Label } from "@rebass/forms"
import Translate from "@components/Translate"

import { useLocation } from "@reach/router"
import { sendDataToMautic } from "@utils/mautic"
import { sendDataToLeadsCenter } from "@utils/leadsCenter"
import { navigate } from "gatsby"
import { goToThanksPage } from "@utils/goToThanksPage"
import { validate } from "@utils/validator"

export const FormSection = ({ formSectionRef, landingPageData, logo }) => {

  const {
    title, subtitle, logos, formHeading,
    callToActionLabel, image, mauticFormId,
    slug
  } = landingPageData

  // use staging form id if porovided,
  // otherwhise use nauticFormId from
  // post markdown metadata
  // PS.: Just set GATSBY_STG_MAUTIC_FORM_ID in development or staging
  const MAUTIC_FORM_ID = process.env.GATSBY_STG_MAUTIC_FORM_ID || mauticFormId

  const location = useLocation()
  const formatMessage = useTranslate()
  const campaignData = useCampaignCookies()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // validate submit form
  const customValidate = async values => {
    const errors = await validate(values, {
      "phone": {
        "required": "Obrigatório",
        "e164": "Número de telefone inválido"
      },
      "firstName": {
        "required": "Obrigatório"
      },
      "lastName": {
        "required": "Obrigatório"
      },
      "email": {
        "required": "Obrigatório",
        "email": "Email inválido"
      }
    })
    return errors
  }

  // handle custom submit form
  const customSubmit = async (formData) => {
    try {
      setIsSubmitting(true)
      const isEbookLp = slug === "/e-book-desenvolvimento-software-aplicativo"
      const firstPage = sessionStorage.getItem("aquisition_page")
      const formId = new URLSearchParams(location.search).get("form_id")

      const data = {
        ...formData,
        formId,
        phone: formData.phone.replace(/[^0-9\.]+/g, ""),
      }

      try {
        await sendDataToMautic(MAUTIC_FORM_ID, data, campaignData, firstPage)
      } catch (error) {
        // console.log(error)
        // mautic throws an error because it returns 302
        // redirecting to a success submit form message
        // throwing an error in localhost
        // - ignore it !!!
      }

      if (isEbookLp) {
        navigate(routes.home)
      } else {
        await sendDataToLeadsCenter(data, campaignData)
        goToThanksPage(data)
      }

    } catch (error) {
      setIsSubmitting(false)
    }
  }

  const { handleChange, errors, values, handleSubmit } = useAskForBudget(MAUTIC_FORM_ID, null, customValidate, customSubmit)

  return (
    <Styles.Section
      backgroundImage={image}
      overlayColor="landingPageOverlayColor"
      ref={formSectionRef}
    >
      <PageGrid>
        {{
          firstPart: (
            <>
              <Styles.Header>
                <Link to={routes.home}>{logo}</Link>
              </Styles.Header>

              <Styles.Title dangerouslySetInnerHTML={{ __html: title }} />
              <Styles.Subtitle
                sx={{
                  display: ["none", "none", "block"],
                }}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
              <Styles.LogosWrapper>
                {(logos || []).map((logo, index) => (
                  <Styles.Logo
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: logo.logo.childSvg?.content,
                    }}
                  />
                ))}
              </Styles.LogosWrapper>
            </>
          ),
          secondPart: (
            <>
              <Styles.FormWrapper>
                <Styles.FormHeading>{formHeading}</Styles.FormHeading>
                <Label>
                  <Translate id="contact.firstName" />
                  <Input
                    id="firstName"
                    name="firstName"
                    required
                    value={values.firstName}
                    erro={errors.firstName}
                    rotulo={formatMessage("contact.placeholders.firstName")}
                    disabled={isSubmitting}
                    onChange={handleChange}
                  />
                </Label>

                <Label>
                  <Translate id="contact.lastName" />
                  <Input
                    id="lastName"
                    name="lastName"
                    required
                    value={values.lastName}
                    erro={errors.lastName}
                    rotulo={formatMessage("contact.placeholders.lastName")}
                    disabled={isSubmitting}
                    onChange={handleChange}
                  />
                </Label>

                <Label>
                  <Translate id="contact.phone" />

                  <InputTelefone
                    id="phone"
                    nome="phone"
                    value={values.phone}
                    erro={errors.phone}
                    onChange={handleChange}
                    required
                    international
                    lingua={process.env.GATSBY_LANGUAGE}
                    disabled={isSubmitting}
                    defaultCountry={
                      process.env.GATSBY_LANGUAGE === "en" ? "US" : "BR"
                    }
                    rotulo={formatMessage("contact.placeholders.phone")}
                  />
                </Label>

                <Label>
                  <Translate id="contact.email" />
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={values.email}
                    erro={errors.email}
                    disabled={isSubmitting}
                    rotulo={formatMessage("contact.placeholders.email")}
                    onChange={handleChange}
                  />
                </Label>

                <Styles.ButtonContent>
                  <Botao
                    type="submit"
                    onClick={handleSubmit}
                    estaCarregando={isSubmitting}
                  >
                    {callToActionLabel}
                  </Botao>
                </Styles.ButtonContent>
              </Styles.FormWrapper>
            </>
          ),
        }}
      </PageGrid>
    </Styles.Section>
  )
}
