import React from "react"
import * as Styles from "./LandingPage.styles"
import { Box } from "rebass"
import { useStaticQuery, graphql } from "gatsby"
import GartnerShape from "@images/svg/gartnerShape.svg"
import { PageGrid } from "./PageGrid"
import Translate, { TranslateHTML } from "@components/Translate"
import { Botao } from "@xapps/design-system"

export const GartnerSection = React.memo(({ show, onClickButton }) => {
  const { gartnerImage } = useStaticQuery(graphql`
    query GartenerImage {
      gartnerImage: file(relativePath: { glob: "gartnerBackground.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      {show && (
        <Styles.Section
          backgroundImage={gartnerImage}
          overlayColor="landingPageOverlayColor"
        >
          <PageGrid
            sx={{
              flexDirection: ["column-reverse", "column-reverse", "row"],
            }}
          >
            {{
              firstPart: (
                <Box sx={{ textAlign: ["center", "center", "start"] }}>
                  <Styles.Title color="white">
                    <p>
                      <TranslateHTML id="gartnerAdvised.title" />
                    </p>
                  </Styles.Title>
                  <Styles.Subtitle color="white">
                    <Translate id="gartnerAdvised.subtitle" />
                  </Styles.Subtitle>
                  <Botao mt={50} onClick={onClickButton}>
                    <Translate id="gartnerAdvised.buttonLabel" />
                  </Botao>
                </Box>
              ),
              secondPart: (
                <Box
                  sx={{
                    textAlign: ["center", "center", "right"],
                    svg: { width: ["250px", "250px", "100%"] },
                  }}
                >
                  <GartnerShape />
                </Box>
              ),
            }}
          </PageGrid>
        </Styles.Section>
      )}
    </>
  )
})
