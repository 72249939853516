import React from "react"
import * as Styles from "./LandingPage.styles"
import { LottieAnimation } from "./LottieAnimation"
import Fade from "react-reveal/Fade"
import { PageGrid } from "./PageGrid"
import { Botao } from "@xapps/design-system"
import { Box } from "rebass"

export const AnimatedSection = React.memo(({ section, onClickButton }) => {
  return (
    <Styles.Section>
      <PageGrid>
        {{
          firstPart: (
            <Fade left>
              <>
                <LottieAnimation lottieFile={section.lottieFile} />
              </>
            </Fade>
          ),
          secondPart: (
            <Fade right>
              <Box sx={{ textAlign: ["center", "center", "start"] }}>
                {section.firstLine && (
                  <Styles.FirstLine>{section.firstLine}</Styles.FirstLine>
                )}
                <Styles.Title
                  color="primary"
                  dangerouslySetInnerHTML={{
                    __html: section.title,
                  }}
                />
                <Styles.Subtitle
                  mb={5}
                  fontSize={[2, 2, "18px"]}
                  color="primary"
                >
                  {section.subtitle}
                </Styles.Subtitle>
                <Botao onClick={onClickButton}>{section.buttonText}</Botao>
              </Box>
            </Fade>
          ),
        }}
      </PageGrid>
    </Styles.Section>
  )
})
