import React from "react"
import { Box, Flex, Heading, Text } from "rebass"
import Image from "gatsby-image"

export const Wrapper = props => (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "flex-start",
    }}
    as="li"
    {...props}
  />
)

export const IconWrapper = props => (
  <Flex
    sx={{
      width: 54,
      minWidth: 54,
      height: 54,
      bg: "white",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.11)",
      borderRadius: 99,
      mr: 3,
    }}
    {...props}
  />
)

export const Icon = props => (
  <Image style={{ width: "32px", height: "32px" }} {...props} />
)

export const ContentWrapper = props => (
  <Box
    sx={{
      width: "100%",
    }}
    {...props}
  />
)

export const Description = props => (
  <Text
    sx={{
      fontSize: 0,
      color: "gray5",
    }}
    {...props}
  />
)

export const Title = props => (
  <Heading
    sx={{
      fontSize: 2,
      color: "primary",
      fontWeight: "bold",
      fontFamily: "body",
      "&::after": {
        content: "'.'",
        color: "secondary",
      },
    }}
    {...props}
  />
)
