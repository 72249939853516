import Translate, { TranslateHTML } from "@components/Translate"
import { Botao } from "@xapps/design-system"
import React from "react"
import * as Styles from "./LandingPage.styles"

export const FooterSection = React.memo(({ showModal, logo }) => {
  const currentYear = new Date().getFullYear()
  return (
    <Styles.FooterWrapper>
      <Styles.LogoWrapper>
        {logo}
        <Styles.Disclaimer>
          {`${currentYear} - `}
          <Translate id="legalDisclaimer" />
        </Styles.Disclaimer>
      </Styles.LogoWrapper>
      <Styles.Footer>
        <div>
          <Styles.FooterTitle>
            <Translate id="footerTitle" />
          </Styles.FooterTitle>
          <TranslateHTML id="footerDescription" />
        </div>
        <Styles.FooterButton>
          <Botao onClick={showModal} variant="apoio" style={{ borderRadius: 50 }}>
            <Translate id="footerButton" />
          </Botao>
        </Styles.FooterButton>
      </Styles.Footer>
    </Styles.FooterWrapper>
  )
})
