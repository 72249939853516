import React from "react"
import * as Styles from "./Topic.styles"

export const Topic = ({ image, title, description }) => (
  <Styles.Wrapper>
    <Styles.IconWrapper>
      <Styles.Icon fixed={image?.childImageSharp?.fluid} />
    </Styles.IconWrapper>
    <Styles.ContentWrapper>
      <Styles.Title>{title}</Styles.Title>
      <Styles.Description>{description}</Styles.Description>
    </Styles.ContentWrapper>
  </Styles.Wrapper>
)
