import React from "react"

export function LottieAnimation({ lottieFile }) {
  if( typeof document !== 'undefined'){
    const { Lottie } = require("react-lottie")
    return !!Lottie && (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: JSON.parse(lottieFile.internal.content),
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
      />
    )
  } else {
    return null;
  }
}
