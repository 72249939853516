import React from "react"
import { Box, Flex, Text } from "rebass"
import Picture from "@images/exitIntent/landing-page-picture.svg"
import { X } from "react-feather"

export const Wrapper = props => (
  <Flex
    sx={{
      px: 4,
      pb: 4,
      pt: 5,
    }}
    {...props}
  />
)

export const FormWrapper = props => (
  <Box
    sx={{
      width: "100%",
    }}
    {...props}
  />
)

export const Label = props => (
  <Text
    sx={{
      fontWeight: "bold",
      fontSize: "26px",
      marginBottom: "8px",
      color: "lightBlack",
    }}
    {...props}
  />
)

export const Description = props => (
  <Text sx={{ marginBottom: 24, color: "lightBlack" }} {...props} />
)

export const DataFormWrapper = props => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: ["100%", "317px"],
      marginLeft: [0, 52],
    }}
    {...props}
  />
)

export const ImageWrapper = ({ Image, ...props }) => (
  <Box
    sx={{
      position: ["absolute", "relative"],
      left: ["25%", 0],
      top: ["-70px", 0],
      width: 255,
      svg: {
        width: [150, 255],
        height: [137, 235],
      },
    }}
    {...props}
  >
    <Picture />
  </Box>
)

export const XIcon = props => (
  <Flex
    sx={{
      position: "absolute",
      top: "16px",
      right: "18px",
      cursor: "pointer",
    }}
    {...props}
  >
    <X />
  </Flex>
)
