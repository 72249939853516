import { Flex } from "rebass"
import React from "react"

export const Wrapper = props => (
  <Flex
    sx={{
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",

      bg: "alternateSectionBackground",
    }}
    {...props}
  />
)

export const Container = props => (
  <Flex
    sx={{
      paddingX: 4,
      paddingY: 5,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      maxWidth: "1090px",
      margin: "0 auto",
    }}
    {...props}
  />
)
export const ContentWrapper = props => (
  <Flex
    sx={{
      flexDirection: ["column", "column", "row"],
      width: "100%",
      alignItems: "center",
      gap: [0, 0, 5],
    }}
    {...props}
  />
)
export const ImageWrapper = props => (
  <Flex
    sx={{
      width: "100%",
      mb: 3,
    }}
    {...props}
  />
)
export const Topics = props => (
  <Flex
    sx={{
      gap: 3,
      flexDirection: "column",
      width: "100%",
    }}
    as="ul"
    {...props}
  />
)
