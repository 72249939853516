import { graphql } from "gatsby"
import React from "react"
import { LandingPage } from "@components/LandingPage"
import SEO from "@components/Seo/Seo.component"
import { useStoreCampaignVariablesInCookies } from "@utils/hooks/useStoreCampaignVariablesInCookies"

function LandingPageTemplate({ data }) {
  const {
    markdownRemark: { frontmatter },
  } = data
  useStoreCampaignVariablesInCookies()

  return (
    <LandingPage {...frontmatter}>
      <SEO
        keywords={frontmatter.keywords}
        title={frontmatter.plainTitle}
        description={frontmatter.plainSubtitle}
      />
    </LandingPage>
  )
}

export const query = graphql`
  query LandingPage($slug: String!) {
    markdownRemark(
      fileAbsolutePath: { glob: "**/content/landing-pages/*.md" }
      fields: { slug: { eq: $slug } }
    ) {
      frontmatter {
        title: markdownField(from: "title")
        subtitle: markdownField(from: "subtitle")
        plainTitle: plainText(from: "title")
        plainSubtitle: plainText(from: "subtitle")
        slug
        keywords
        callToActionLabel
        formHeading
        showGartner
        showTestimonies
        mauticFormId
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logos {
          logo {
            childSvg {
              content
            }
          }
        }
        sections {
          title: markdownField(from: "title")
          buttonText
          subtitle
          firstLine
          lottieFile {
            internal {
              content
            }
          }
        }
        specialBlock1 {
          title: markdownField(from: "title")
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          topics {
            title
            description
            image {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        specialBlock2 {
          title: markdownField(from: "title")
          text: markdownField(from: "text")
          image {
            childImageSharp {
              fluid(maxHeight: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default LandingPageTemplate
