import React, { forwardRef } from "react"
import { Flex, Heading, Text, Box } from "rebass"
import BackgroundImage from "gatsby-background-image"
import { Formik } from "formik"

const Container = props => (
  <Flex
    sx={{
      maxWidth: ["100vw", "100vw", 1366],
      width: "100%",
      mx: "auto",
      paddingX: [3, 3, 3],
      paddingY: 4,
    }}
    {...props}
  />
)

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

function OverlayedBackgroundImage({ image, color, children }) {
  return (
    <ConditionalWrapper
      condition={image}
      wrapper={children => (
        <BackgroundImage
          Tag={`section`}
          id={`test`}
          style={{
            width: "100%",
            height: "100%",
          }}
          fluid={image.childImageSharp.fluid}
        >
          {children}
        </BackgroundImage>
      )}
    >
      <Overlay color={color}>{children}</Overlay>
    </ConditionalWrapper>
  )
}

export function Wrapper(props) {
  return (
    <Flex
      sx={{
        width: "100vw",
        height: "100vh",
        maxWidth: "100%",
        flexWrap: "wrap",
        position: "relative",
      }}
      {...props}
    />
  )
}

export const Section = forwardRef(
  (
    { backgroundColor = "white", backgroundImage, overlayColor, children },
    ref
  ) => (
    <Flex
      ref={ref}
      as="section"
      sx={{
        width: "100vw",
        maxWidth: "100%",
        minHeight: "100vh",
        backgroundColor: backgroundColor,
        alignItems: "center",
      }}
    >
      <OverlayedBackgroundImage image={backgroundImage} color={overlayColor}>
        <Container>{children}</Container>
      </OverlayedBackgroundImage>
    </Flex>
  )
)

export const Disclaimer = props => (
  <Text
    sx={{
      width: "100%",
      mt: 2,
      fontSize: [1, 1, 2],
    }}
    {...props}
  />
)
export const FooterWrapper = props => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: ["1fr", "1fr 1fr"],
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "blueDark",
      width: "100%",
      paddingY: 5,
      paddingX: [3, 3, 5],
      color: "white",
      fontFamily: "heading",
    }}
    {...props}
  />
)

export const FooterTitle = props => (
  <Text
    sx={{
      fontSize: "26px",
      fontWeight: "heading",
    }}
    {...props}
  />
)

export const FooterButton = props => (
  <Flex
    sx={{
      pt: [4, 4, 0],
      margin: "auto",
      marginLeft: [0, "auto"],
    }}
    {...props}
  />
)

export const ButtonContent = props => (
  <Flex
    sx={{
      justifyContent: "center",
    }}
    {...props}
  />
)

export const Footer = props => (
  <Box
    sx={{
      mt: [3, 0],
      display: "grid",
      gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
      textAlign: ["start", "center", "start"],
    }}
    {...props}
  />
)

export const LogoWrapper = props => (
  <Flex
    sx={{
      alignItems: "flex-start",
      flexDirection: "column",
    }}
    {...props}
  />
)

export const TitleWrapper = props => (
  <Box
    sx={{
      width: 1 / 2,
      px: 2,
      color: "white",
      fontWeight: "body",
      strong: {
        fontWeight: "bold",
      },
    }}
    {...props}
  />
)

export const Header = props => (
  <Box
    sx={{
      top: [0, 0, 48],
      width: ["100%", "auto", "auto"],
      mb: [32, 32, 0],
      position: ["relative", "relative", "absolute"],
    }}
    {...props}
  />
)

export const FormWrapper = forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="form"
    sx={{
      backgroundColor: "gray2",
      boxShadow: "form",
      borderColor: "grayLight2",
      borderWidth: "1px",
      borderStyle: "solid",
      py: 4,
      px: 3,
      borderRadius: "box",
      "& > *:not(:last-child)": {
        mb: [2, 2, 3],
      },

      ".PhoneInput": {
        backgroundColor: "#fff",
        borderRadius: "4px",
      },
      ".icone-de-status": {
        margin: "0px !important",
      },
    }}
    {...props}
  />
))

export const FormHeading = props => (
  <Text
    sx={{
      fontWeight: "bold",
      color: "lightBlack",
      textAlign: "center",
      fontSize: [2, 2, 4],
    }}
    {...props}
  />
)

export const LogosWrapper = props => (
  <Box
    sx={{
      mt: 25,
      display: ["none", "none", "inline-grid"],
      gridGap: ["16px", "35px", "35px"],
      justifyItems: "left",
      alignSelf: "flex-start",
      gridTemplateColumns: "repeat(3, minmax(50px, 1fr))",
      float: "left",
    }}
    {...props}
  />
)

export const Logo = props => (
  <Box
    sx={{
      svg: {
        path: {
          fill: "white",
        },
        height: [20, 20, 30],
        width: [80, 80, 90],
      },
    }}
    {...props}
  />
)

export const Overlay = ({ color, ...props }) => (
  <Flex
    sx={{
      width: "100%",
      height: "100%",
      backgroundColor: color,
    }}
    {...props}
  />
)

export const Title = ({ color = "inherit", ...props }) => (
  <Heading
    sx={{
      fontSize: [24, 24, 40],
      lineHeight: 1.25,
      mb: 4,
      color,
      textAlign: ["center", "center", "left"],
      fontWeight: "body",
      "p::after": {
        content: "'.'",
        color: "secondary",
      },

      strong: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
      },
    }}
    as="h1"
    {...props}
  />
)

export const Subtitle = ({
  color = "inherit",
  fontSize = [2, 2, 4],
  sx,
  ...props
}) => (
  <Text
    sx={{
      ...sx,
      textAlign: ["center", "center", "left"],
      color,
      fontSize,
      mb: 2,
    }}
    {...props}
  />
)

export const FirstLine = ({ ...props }) => (
  <Text
    sx={{
      textAlign: ["center", "center", "left"],
      fontWeight: "bold",
      fontFamily: "heading",
      color: "primary",
      fontSize: [2, 2, 3],
      mb: 2,
    }}
    {...props}
  />
)
