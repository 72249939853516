import React from "react"
import { Flex, Box } from "rebass"

const Grid = ({ children, sx }) => (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "center",
      mx: [0, 0, -2],
      width: "100%",
      flexWrap: "wrap",
      flexDirection: ["column", "column", "row"],
      ...sx,
    }}
  >
    {children}
  </Flex>
)
Grid.Column = ({ width, ...props }) => (
  <Box
    sx={{
      px: [0, 0, 2],
    }}
    width={width}
    {...props}
  />
)

export const PageGrid = React.memo(
  ({ children: { before, fullWidth, firstPart, secondPart }, sx }) => (
    <Grid sx={sx}>
      {before}
      {fullWidth && (
        <Grid.Column color="white" width={[1, 1, 10 / 12]}>
          {fullWidth}
        </Grid.Column>
      )}
      {!fullWidth && (
        <>
          <Grid.Column color="white" width={[1, 1, 5 / 12]}>
            {firstPart}
          </Grid.Column>
          <Grid.Column color="white" width={[1, 1, 5 / 12]}>
            {secondPart}
          </Grid.Column>
        </>
      )}
    </Grid>
  )
)
